/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import Slider from "react-slick";
import utils from '../../../utils';
import cmsUtils from '../../../cms/utils/cmsUtils';
import { SiteLink } from '../../../components';

export function SliderAwardPanel(props) {
  const item = props.item;
  //const defaultDesH = 220;
  const subItems = item.items || [];
  //console.log(subItems.length);
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames('cms_item', 'sliderAwardPanel', item.cssClass || item.anchorName || '');

  const sliderAwardItems = subItems.map((subItem, index) => {
    const imageUrl = cmsUtils.payload(subItem, 'ImageUrl');
    const bottomText = cmsUtils.payload(subItem, 'BottomText');
    const linkUrl = cmsUtils.payload(subItem, 'LinkUrl');
    return (
      <div key={index} css={style.sliderImageWrapper}>
        {
          (linkUrl && linkUrl !== '') ? 
          <SiteLink to={linkUrl}>
          <div css={style.sliderImageWrapper2}>
            <img css={style.sliderImage} key={subItem.itemId} alt={'sliderimage-' + index} src={imageUrl} />
            <h5 css={style.bottom__text}>{bottomText}</h5>
          </div>
        </SiteLink> : 
        
        <div css={style.sliderImageWrapper2}>
          <img css={style.sliderImage} key={subItem.itemId} alt={'sliderimage-' + index} src={imageUrl} />
          <h5 css={style.bottom__text}>{bottomText}</h5>
        </div>
      
        }
        
        
      </div>
    )
    }
  );

  var numOfSlides = (maxNum) => subItems.length >= maxNum ? maxNum : subItems.length

  var settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    ////fade: true,
    autoplay: true,
    slidesToShow: numOfSlides(6),
    //slidesToShow: 3,
    slidesToScroll: numOfSlides(3),
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: numOfSlides(5), slidesToScroll: numOfSlides(2) } },
      { breakpoint: 992, settings: { slidesToShow: numOfSlides(4), slidesToScroll: numOfSlides(2) } },
      { breakpoint: 768, settings: { slidesToShow: numOfSlides(3), slidesToScroll: numOfSlides(2) } },
      { breakpoint: 576, settings: { slidesToShow: numOfSlides(2), slidesToScroll: numOfSlides(1)} }
    ]
  };

  return (
    <div css={style.sliderAwardPanel} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <Slider {...settings}>
        {sliderAwardItems}
      </Slider>
    </div>
  );
}