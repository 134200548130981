const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  colorBlue : '#0d6cb4',
  colorBlack : '#252525',
  colorGrey : '#495057',
  colorOrange : '#e2953e',
  colorYellow : '#fad509'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',

  primaryColor: '#c20f2f',
  fontColor: colors.colorGrey,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;