//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {
  pageTiles: css({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    margin: '2rem -10px',
    overflow: 'hidden'
  }),

  slider: css({
    '.slick-prev': css(mq({
      left: ['0px !important', null, '-20px !important'],
    })),
    '.slick-next': css(mq({
      right: ['0px !important', null, '-20px !important']
    })),
    '.slick-next:before, .slick-prev:before': css({
      color: 'rgb(31, 79, 156)'
    })
  }),

  pageTile__tile: css(mq({
    flexGrow: '1',
    flexShrink: '1',
    padding: '20px',

    //for type1
    flexBasis: ['100%', null, '50%', '33.3%'],
    maxWidth: ['100%', null, '50%', '33.3%'],

    '&.type1.useSlider': css({
      flexBasis: '100%',
      maxWidth: '100%',
      height: '100%'
    })

  })),

  tile__content: css({
    '&:hover': {
      textDecoration: 'none',
      color : variables.fontColor,
      '.pageTile__sharkCage__bg': css({
        //transform: 'scale(1.05)'
      }),
      '.pageTile__sharkCage__details' : {
        backgroundColor: variables.colorBlue
      },
      '.info-wrapper': {
        '.info-title, .info-des': {
          color: 'white'
        }
      }
    },
    color: variables.fontColor,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }),

  tile__bg__wrapper: css({
    //height:'80%',
    overflow: 'hidden',
    flex: '0 0 auto',
    zIndex: 2
  }),

  tile__bg: css(mq({
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    transition: '.5s',
    height : '0px',
    borderRadius: '100%',
    paddingBottom : '100%',
    zIndex: 2
  })),

  tile__details__container : {
    marginTop:'-50%',
    position : 'relative',
    filter: 'drop-shadow(-1px 1px 3px rgba(0, 0, 0, 0.1))',
    height: '100%'
  },

  tile__details__container__border : {
    position : 'absolute',
    backgroundColor : variables.colorOrange,
    width: '100%',
    height: '20%',
    bottom: '-3px',
    clipPath : 'polygon(0% 0%, 100% 0px, 100% calc(100% - 2rem), 50% 100%, 0px calc(100% - 2rem))',
    zIndex : '-1'
  },

  tile__details: css(mq({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding : '55% 20px 20px',
    backgroundColor : 'white',
    clipPath : 'polygon(0% 0%, 100% 0px, 100% calc(100% - 2rem), 50% 100%, 0px calc(100% - 2rem))'
  })),
  info__wrapper: css({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    position: 'relative'
  }),
  info__title: css({
    fontWeight: '600',
    color: variables.colorBlue,
    fontSize: '48px',
    lineHeight: 1.1,
    marginTop : '0.5rem'
  }),
  info__des: css({
    fontSize: '0.8rem',
    flexGrow: 1,
    //color: 'white',
    textAlign : 'center',
    marginTop : '0.5rem'
  }),
  infoDesc: {
    display: '-webkit-box',
    WebkitLineClamp: '6',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  infoBottom : {
    height: '1.5rem'
  }
}