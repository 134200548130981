//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {
    pageTiles: css({
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        margin: '2rem -10px',
        overflow: 'hidden'
    }),
    pageTile__tile: css(mq({
        flex: '0 0 100%',
        maxWidth: '100%',
        padding: '10px',
        '&:nth-of-type(even)': {
            '.pageTile__bsyc__bg': mq({
                //order: ['0', null, '2'],
            }),
            '.pageTile__bsyc__content__details': mq({
                //order: '1'
            })
        }
    })),

    tile__content: css({
        '&:hover': {
            textDecoration: 'none',
            '& .pageTile__bsyc__content__details': {
                //color: 'white',
                //backgroundColor: variables.colorYellow
                // '&.default' : css({
                //      backgroundColor: 'rgba(0,113,188,1)'
                // }),
                // '&.red' : css({
                //      backgroundColor: 'rgba(211,61,2,1)'
                // }),
                // '&.green' : css({
                //      backgroundColor: 'rgba(0, 146, 69,1)'
                // })
            },
            '& .pageTile__bsyc__bg2': {
                //transform: scale(1.05)
                transform: 'scale(1.05)'
            },
            '& .pageTile__bsyc__learn__more__text' : {
                backgroundColor : variables.colorOrange,
                color : 'white !important'
            }
        }
    }),

    tile__flip__wrapper: css({
        zIndex: '-1',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        position: 'relative',
        flexWrap: 'wrap',
        boxShadow : '-1px 1px 5px rgb(190 190 190 / 50%)'
    }),

    tile__bg: css(mq({
        height: '0',
        paddingTop: ['50%', '19%', '33%'],
        //height: ['50%','35%','30%'],
        
        //flex: '1 1 300px',

        flex: ['1 0 100%',null,'1 0 40%'],
        maxWidth : ['100%',null,'40%'],


        
        overflow: 'hidden',
        position: 'relative'
    })),

    tile__bg2: css(mq({
        height: '100%',
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        //overflow:'hidden',
        transition: 'all .2s',
        position: 'absolute',
        top: '0px',
        width: '100%'
    })),

    tile__details: css(mq({
        overflow: 'hidden',
        flexGrow: '1',
        //textAlign: 'center',
        width: '100%',
        bottom: '0',
        backgroundColor: 'white',
        
        //flex: '1 1 300px',
        flex: ['1 0 100%',null,'1 0 60%'],
        maxWidth : ['100%',null,'60%'],
        
        transition: 'all .2s ease-in-out',
        position: 'relative',
        '& .pageTile__bsyc__container': mq({
            //position: 'absolute',
            position: ['static,', null, 'absolute'],
            //border: '1px solid red',
            width : '100%',
            height: '100%',
            top : '0px',
            // top: '50%',
            // left: '50%',
            // transform: ['none', null, 'translate(-50%, -50%)'],
            //width: ['100%', null, '90%'],
            //padding: ['1rem', null, '0px'],
            padding : ['1rem',null,'2rem 3rem'],
            '& .pageTile__bsyc__title': mq({
                //fontFamily: 'proxima-soft, sans-serif',
                fontSize: ['26px',null,'32px'],
                //textTransform: 'uppercase',
                fontWeight: '600',
                lineHeight: '1',
                margin: '0',
                //paddingTop: '.5rem',
                //paddingBottom: '.5rem',
                color: variables.colorBlue,
                maxWidth: '410px'
            }),
            '& .pageTile__bsyc__desc': mq({
                lineHeight: '1.3',
                //fontSize: '.9rem',
                //color: '#3d3d3d',
                //minHeight: '70px',
                color: variables.fontColor,
                marginTop: ['1rem',null,'1.5rem']
            }),
            '& .pageTile__bsyc__learn__more' : mq({
                marginTop: ['1rem',null,'1.5rem'],
                '.pageTile__bsyc__learn__more__text' : {
                    color : variables.colorOrange,
                    display : 'inline-block',
                    border : '2px solid ' + variables.colorOrange,
                    fontWeight : '700',
                    padding : '0.3rem 1rem',
                    borderRadius: '10px'
                }
            })
        })
    }))
}